<template>
<div>
    <div class="year-calender-nav d-flex align-items-center mb-4 pt-2">
        <a @click="movePrevMonth">
            <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
                <path data-v-19b6cf78="" d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z">
                </path>
            </svg>
        </a>
        <div class="year-title">{{curMonthName}} {{ curYear }}</div>
        <a @click="moveNextMonth">
            <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
                <path data-v-19b6cf78="" d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z">
                </path>
            </svg>
        </a>
    </div>
    <div class="p-2">

        <FullCalendar class='demo-app-calendar' :options='calendarOptions'>
            <template v-slot:eventContent='arg'>

                <div class="cal-content"> 
                  
                  <div class="floatleft">  {{ arg.event.title }}   <i class="glyphicon glyphicon-map-marker"> </i> </div> 
                  
                    <ul class="members_list">
                        <li>
                            <figure><img src="@/assets/images/user.png"></figure>
                        </li>
                        <li>
                            <figure><img src=""></figure>
                        </li>
                        <li>
                            <figure><img src="@/assets/images/user.png"></figure>
                        </li>
                    </ul>
                </div>
            </template>
        </FullCalendar>

    </div>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'

export default {
    components: {
        FullCalendar,
    },
    data() {

        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin
                ],
                headerToolbar: false,
                initialView: 'timeGridDay',
                events: [{
                    title: 'VIP Visit',
                    start: "2021-04-16T10:30:00+00:00",
                    end: "2021-04-16T11:30:00+00:00"
                }, {
                    title: 'My Visit',
                    start: "2021-04-16T13:30:00+00:00",
                    end: "2021-04-16T14:30:00+00:00"
                }],
                editable: false,
                height: 'auto',
                selectable: false,
                selectMirror: false,
                dayMaxEvents: true,
                weekends: true
            }
        };
    },
    mounted() {},
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.cal-content {
    color: #000;
    display: flex;
}
.fc-event-main{ }
.fc-event-main i{ padding:10px 0px !important;color: #000; font-weight: bold; font-style: normal;}
.floatleft{

  position: absolute;
  left:10px;
  top:10px;
  font-weight: bold;
}
.members_list {
    display: flex;
    position: absolute;
    right: 20px;
    top: 10px;

    li {
        width: auto !important;
        margin-right: -10px;

        figure {
            border: 1px solid #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            overflow: hidden;
            margin: 0px;
            background: #2A85D0;

            img {
                width: 100%;
                min-height: 100%;
                display: block;
            }
        }
    }
}

.fc-event,
.fc-event-dot {
    background: rgba(217, 14, 14, 0.2) !important;
    border-left: 2px solid #D90E0E !important;
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    display: none;
}

/deep/ .custom-calendar.vc-container {
    background: transparent;

    border-radius: 0;
    width: 100%;

    & .vc-header,
    .vc-arrows-container {
        display: none;
    }

    & .vc-weeks {
        padding: 0;
    }

    & .vc-weekday {
        padding: 10px 0;
        font-weight: 400;
        font-size: 16px;
        color: #8781AD;
        text-transform: uppercase;
        border-left: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;

        &:first-child {
            border-left: none;
        }
    }

    & .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        border-left: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;
        border-top: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;
        min-height: 150px;

        &.is-not-in-month {
            * {
                opacity: 1;
                color: #BBBBBB;
            }
        }

        &.weekday-position-1 {
            border-left: none;
        }

        &.on-top {
            border-top: none
        }

        &.weekday-0 {
            border-left: var(--day-border-highlight);
        }

        &:not(.on-right) {
            border-right: var(--day-border);
        }
    }

    & .vc-day-dots {
        margin-bottom: 5px;
    }

    .day-label {
        font-size: 22px;
        padding: 5px 0;
        font-weight: 600;

    }
}

.month-calendar {
    .events {
        padding-left: 0 !important;
        border-left: none;
        font-size: 14px;

        .item-title {
            max-width: 70px;
        }
    }

    .more-item {
        padding: 10px 0 5px;
        text-align: right;
        font-weight: 600;
    }
}
</style>
