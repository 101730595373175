<template>
  <div>
    <div class="year-calender-nav d-flex align-items-center mb-4 pt-2">
      <a @click="movePrevMonth">
        <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
          <path data-v-19b6cf78=""
            d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z">
          </path>
        </svg>
      </a>
      <div class="year-title">{{curMonthName}} {{ curYear }}</div>
      <a @click="moveNextMonth">
        <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
          <path data-v-19b6cf78=""
            d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z">
          </path>
        </svg>
      </a>
    </div>
    <div class="p-2">
    <vc-calendar class="custom-calendar month-calendar max-w-full" :masks="masks" :from-page="{ month: curMonth, year: curYear }"
      :attributes="attributes" disable-page-swipe is-expanded title-position="left" :first-day-of-week="2">
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <div class="day-label text-center">{{ day.day }}</div>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
          
             <div class="events pl-3"  v-for="(attr,i) in attributes" :key="i">
                  <ul>
                    <li class="d-flex"  v-for="(items, index) in attr.customData.slice(0,3)" :key="index"   >
                      <i :class="items.priority"></i> <div  class="d-inline-block text-truncate item-title">{{items.title}}</div> 
                    </li> 
                  </ul>
                 <div  v-if="attr.customData.length > 3" class="more-item">{{attr.customData.length - 3}} More</div>
                </div>

          </div>
        </div>
      </template>
    </vc-calendar>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      let month = new Date().getMonth();
      let year = new Date().getFullYear();
      return {
        curMonth: month + 1,
        curYear: year,
        selectedDate: new Date(),
        calendar: this.$refs.calendar,
        yearIndex: 0,
        customDate: new Date(),
        curMonthName: '',
        monthName: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
          'November', 'December'
        ],

        masks: {
          weekdays: "WWW",
        },
        attributes: [{
            key: 1,
            customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              },
              {
                title: "Noah's basketball",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              },
              {
                title: "Noah's basketball",
                priority: 'heigh'
              },

            ],
            dates: new Date(2021, 2, 1),
          },
          {
            key: 2,
           customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              } 

            ],
            dates: new Date(2021, 2, 2),
          },
          {
            key: 3,
             customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              }, 
              {
                title: "VIP Visit",
                priority: 'low'
              },
              {
                title: "Noah's basketball",
                priority: 'heigh'
              },

            ],
            dates: new Date(2021, 2, 5),
          },
          {
            key: 4,
            customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              },

            ],
            dates: new Date(2021, 2, 17),
          },
          {
            key: 4,
             customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              },

            ],
            dates: new Date(2021, 2, 8),
          },

          {
            key: 5,
            customData: [{
                title: "Appointment",
                priority: 'heigh'
              },
              {
                title: "My Visit",
                priority: 'medium'
              },
              {
                title: "VIP Visit",
                priority: 'low'
              },

            ],
            dates: new Date(2021, 2, 10),
          },
        ],
      };
    },
    mounted() {
      this.curMonthName = this.monthName[this.curMonth - 1];
    },
    methods: {
      moveNextMonth() {
        this.curMonth++;
        this.curMonthName = this.monthName[this.curMonth - 1];
        if (this.curMonth > 12) {
          this.curYear++;
          this.curMonth = 1
          this.curMonthName = this.monthName[this.curMonth - 1];
        }
      },
      movePrevMonth() {
        this.curMonth--;
        this.curMonthName = this.monthName[this.curMonth - 1];
        if (this.curMonth < 1) {
          this.curYear--;
          this.curMonth = 12
          this.curMonthName = this.monthName[this.curMonth - 1];
        }

      },
    },
  };
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  /deep/ .custom-calendar.vc-container {
    background: transparent;

    border-radius: 0;
    width: 100%;

    & .vc-header,
    .vc-arrows-container {
      display: none;
    }

    & .vc-weeks {
      padding: 0;
    }

    & .vc-weekday {
      padding: 10px 0;
      font-weight: 400;
      font-size: 16px;
      color: #8781AD;
      text-transform: uppercase;
      border-left: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;

      &:first-child {
        border-left: none;
      }
    }

    & .vc-day {
      padding: 0 5px 3px 5px;
      text-align: left;
      height: var(--day-height);
      min-width: var(--day-width);
      border-left: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;
      border-top: rgba($color: #C4C4C4, $alpha: 0.6) solid 1px;
      min-height: 150px;
      &.is-not-in-month {
        *{
        opacity: 1;
        color: #BBBBBB;}
      }
      &.weekday-position-1 {
        border-left: none;
      }

      &.on-top {
        border-top: none
      }

      &.weekday-0 {
        border-left: var(--day-border-highlight);
      }

      &:not(.on-right) {
        border-right: var(--day-border);
      }
    }

    & .vc-day-dots {
      margin-bottom: 5px;
    }

    .day-label {
      font-size: 22px;
      padding: 5px 0;
      font-weight: 600;

    }
  }

  .month-calendar {
    .events {
      padding-left: 0 !important;
      border-left: none;
      font-size: 14px; 
       .item-title{
         max-width: 70px;
       }
    }
    .more-item {
      padding: 10px 0 5px;
      text-align: right;
      font-weight: 600;
    }
  }
</style>