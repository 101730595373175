<template>
    <div>
          <div class="year-calender-nav d-flex align-items-center mb-4 pt-2">
            <a @click='moveNextYear'>
              <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
                <path data-v-19b6cf78=""
                  d="M11.196 10c0 0.143-0.071 0.304-0.179 0.411l-7.018 7.018 7.018 7.018c0.107 0.107 0.179 0.268 0.179 0.411s-0.071 0.304-0.179 0.411l-0.893 0.893c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-8.321-8.321c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l8.321-8.321c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l0.893 0.893c0.107 0.107 0.179 0.25 0.179 0.411z">
                </path>
              </svg>
            </a>
            <div class="year-title"> {{ curYear }} </div><a @click='movePrevYear '>
              <svg data-v-19b6cf78="" width="14px" height="26px" class="vc-svg-icon">
                <path data-v-19b6cf78=""
                  d="M10.625 17.429c0 0.143-0.071 0.304-0.179 0.411l-8.321 8.321c-0.107 0.107-0.268 0.179-0.411 0.179s-0.304-0.071-0.411-0.179l-0.893-0.893c-0.107-0.107-0.179-0.25-0.179-0.411 0-0.143 0.071-0.304 0.179-0.411l7.018-7.018-7.018-7.018c-0.107-0.107-0.179-0.268-0.179-0.411s0.071-0.304 0.179-0.411l0.893-0.893c0.107-0.107 0.268-0.179 0.411-0.179s0.304 0.071 0.411 0.179l8.321 8.321c0.107 0.107 0.179 0.268 0.179 0.411z">
                </path>
              </svg>
            </a>
            <div class="ml-auto">
              <b-button v-b-modal="'reminder-modal'">Set Reminder</b-button>
            </div>
          </div> 
          <vc-calendar :columns="layout.columns" :rows="layout.rows" ref="calendar" headerArrows="" :is-expanded=true
            :from-page="{ month: 1, year: curYear }" :is-linked=false :attributes="attributes" title-position="left"
            class="vc-year-calendar" @dayclick='dayClicked'>
            <div slot="day-popover">
              <div class="d-flex">
                <div class="text-center mr-3">
                  <div class="day-title">{{selectedDay.day}}</div>
                  <div class="day-number">{{weekName}}</div>   
                </div>
                <div class="events pl-3">
                  <ul>
                    <li class="d-flex">
                      <div class="mr-3">10:00 am - 10:30 am</div>
                      <div><i class="heigh"></i> Appointment</div>
                    </li>
                    <li class="d-flex">
                      <div class="mr-3">10:30 am - 11:00 am</div>
                      <div><i class="medium"></i> My Visit</div>
                    </li>
                    <li class="d-flex">
                      <div class="mr-3">11:00 am - 11:30 am</div>
                      <div><i class="low"></i> VIP Visit</div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </vc-calendar>
          <b-modal ref="reminder-modal" id="reminder-modal" centered hide-footer hide-header size="650"   >
            <schedule-reminder></schedule-reminder>
          </b-modal>
        </div>
</template>
<script>
import scheduleReminder from "@/views/schedule/scheduleReminder";
  export default {
    components:{
      scheduleReminder
    },
    data() {
      let today = new Date();
      let year = today.getFullYear();
      return {
        curYear: year,
        selectedDate: new Date(),
        calendar: this.$refs.calendar,
        yearIndex: 0, 
        customDate: new Date(),
        selectedDay:'',
        weekName:'',
        attributes: [{
          dot: {
            style: {
              backgroundColor: '#8781AD',

            },
          },
          popover: {
            visibility: 'click',
            hideIndicator: true,

          },
          customData: {
            name: 'ravi'
          },
          dates: [
            new Date(2021, 0, 1), // Jan 1st
            new Date(2021, 0, 10), // Jan 10th
            new Date(2021, 0, 22), // Jan 22nd
            new Date(2021, 1, 24), // Jan 22nd
            new Date(2021, 2, 22), // Jan 22nd
          ],
        }, ]


      }
    },
    computed: { 
      layout() {
        return this.$screens({ 
          default: {
            columns: 1,
            rows: 1,
            isExpanded: true,
          }, 
          // Override for large screens
          lg: {
            columns: 3,
            rows: 4,
            isExpanded: false,
          },
        }, );
      },

    },
    methods: {

      moveNextYear() {
        this.curYear--;
        this.selectedDat = new Date(this.curYear)
      },
      movePrevYear() {
        this.curYear++;
        this.selectedDat = new Date(this.curYear)
      },
     
       dayClicked(day) {
      this.selectedDay = day;
      let dayName =['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        this.weekName = dayName[this.selectedDay.weekday]; 
    },
    }, 
  }
</script>