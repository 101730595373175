<template>
  <div>
    <div class="page-header justify-content-start">
      <label class="page-title">Schedule</label>
      <label class="page-sub-title">{{SchedulePeriod}}</label>
    </div>
    <div class="filter-sec  d-flex align-items-end mb-4">
      <div class="mr-4">
        <b-button-group size="sm">
          <b-button @click.prevent="filterOnline('All')" :class="{ active : filterOnlineItem == 'All' }">All</b-button>
          <b-button @click.prevent="filterOnline('Online')" :class="{ active : filterOnlineItem == 'Online' }">Online
          </b-button>
          <b-button @click.prevent="filterOnline('Offline')" :class="{ active : filterOnlineItem == 'Offline' }">Offline
          </b-button>
        </b-button-group>
      </div>
      <div class="mr-3">
        <b-button-group size="sm">
          <b-button @click.prevent="filterLocation('All')" :class="{ active : filterLocationItem == 'All' }">All
          </b-button>
          <b-button @click.prevent="filterLocation('External')" :class="{ active : filterLocationItem == 'External' }">
            External</b-button>
          <b-button @click.prevent="filterLocation('Internal')" :class="{ active : filterLocationItem == 'Internal' }">
            Internal</b-button>
        </b-button-group>
      </div>

      <div class="ml-auto d-flex filter-group">
        <div>
          <div class="dd-title">Meeting</div>
          <b-dropdown class="dropdown-btn" right>
            <template #button-content>
              {{meetingText}}
            </template>
            <b-dropdown-item @click="selectMeeting(mettingName)" v-for="(mettingName, id) in mettingList" :key="id">
              {{mettingName}}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="ml-4">
          <div class="dd-title">Location</div>
          <b-dropdown class="dropdown-btn" right>
            <template #button-content>
              {{locationText}}
            </template>
            <b-dropdown-item @click="selectLocation(locationName)" v-for="(locationName, index) in locationList"
              :key="index">{{locationName}}</b-dropdown-item>

          </b-dropdown>
        </div>
        <div class="ml-4">
          <div class="dd-title">Time range</div>
          <b-dropdown class="dropdown-btn" right>
            <template #button-content>
              {{timeRangeText}}
            </template>
            <b-dropdown-item @click="selectTimeRange(timeRange)" v-for="(timeRange, index1) in timeRangeList"
              :key="index1">{{timeRange}}</b-dropdown-item>

          </b-dropdown>
        </div>
      </div>
    </div>
    <b-row class="calader_wrap"> 
      <b-col class="calader_left"> 
              <div class="calendar-wrappper" v-if="SchedulePeriod == 'All'"> 
          <scheduleYear></scheduleYear>
        </div>
        <div class="calendar-wrappper" v-if="SchedulePeriod == 'Year'"> 
          <scheduleYear></scheduleYear>
        </div>
             <div class="calendar-wrappper" v-if="SchedulePeriod == 'Month'"> 
          <scheduleMonth></scheduleMonth>
        </div>
              <div class="calendar-wrappper" v-if="SchedulePeriod == 'Today'"> 
          <scheduleDaily></scheduleDaily>
        </div>
             <div class="calendar-wrappper" v-if="SchedulePeriod == 'Week'"> 
          <scheduleWeek></scheduleWeek>
        </div>
      </b-col>
      <b-col class="calader_right">
        <vc-date-picker mode="date" v-model="customDate" title-position="left" class="custom-date-picker" />

      </b-col>

    </b-row>


  </div>
</template>
<script>
  import scheduleYear from '@/views/schedule/scheduleYear.vue'
  import scheduleMonth from '@/views/schedule/scheduleMonth.vue'
    import scheduleDaily from '@/views/schedule/scheduleDaily.vue'
    import scheduleWeek from '@/views/schedule/scheduleWeekly.vue'

  export default {
    components: {
      scheduleYear,
      scheduleMonth,
      scheduleDaily,
      scheduleWeek
    },
    data() {
      let today = new Date();
      let year = today.getFullYear();
      return {
        curYear: year,
        selectedDate: new Date(),
        calendar: this.$refs.calendar,
        yearIndex: 0,
        filterOnlineItem: '',
        filterLocationItem: '',
        meetingText: 'All',
        locationText: 'All',
        timeRangeText: 'All',
        mettingList: ["VIP Meeting", "My Visit", "Webinar", "Appointment", "All"],
        locationList: ["Bangalore", "Mumbai", "Pune", "Chennai", "Delhi", "All"],
        timeRangeList: ["Today", "Week", "Month", "Year", "All"], 
        customDate: new Date(),
        SchedulePeriod:'Year',

      }
    },
    computed: {

    },
    methods: {
      filterOnline(item) {
        this.filterOnlineItem = item
      },
      filterLocation(item1) {
        this.filterLocationItem = item1
      },
      selectMeeting(item) {
        this.meetingText = item
      },
      selectLocation(item) {
        this.locationText = item
      },
      selectTimeRange(item) {
        this.timeRangeText = item
        this.SchedulePeriod = item
      },
    }
  }
</script>