<template>
  <div class="reminder-modal">
    <div class="modal-header">
      <a class="modal-close" @click="$bvModal.hide('reminder-modal')"></a>
    </div>

    <div class="modal-container">
      <b-row>
        <b-col cols="4">
          <div class="badge-title">Webinar</div>
          <div class="mt-3">21st Nov '20</div>
          <div class="mt-2">03:00 pm - 05:00 pm</div>
          <div class="mt-3">Online <img src="@/assets/images/map-icon.svg"></div>
          <div class="mt-1"><a href="#">Zoom <img src="@/assets/images/help-icon.svg"></a></div>

        </b-col>

        <b-col cols="8">
          <div class="d-flex">

            <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem"></b-avatar>
            <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem"></b-avatar>
            <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem"></b-avatar>
            +96

            <div class="ml-auto">
              <img src="@/assets/images/attach-icon.svg"> <a href="">2</a>
            </div>
          </div>
          <h4 class="mt-3">Master Class With Daaji</h4>
          <div class="mt-4">
            <b-form-textarea class="form-control" :rows="3"></b-form-textarea>
          </div>
        </b-col>

      </b-row>
      <div class="reminder-accordion mt-4" role="tablist">
        <b-card no-body>
          <h4 v-b-toggle.organisation class="d-flex">ORGANISATION DETAIL <img :src="expandArrow" class="ml-auto"></h4>
          <b-collapse id="organisation" accordion="reminder-accordion" class="accordion-body">
            <div class="py-3">
              <table class="w-100">
                <tr>
                  <td class="w-25">Name</td>
                  <td>Sunil Rao</td>
                </tr>
                <tr>
                  <td class="w-25">Mobile Number</td>
                  <td>+91 984722983</td>
                </tr>
                <tr>
                  <td class="w-25">Email ID</td>
                  <td>hemesh.j@gmail.com</td>
                </tr>
                <tr>
                  <td class="w-25">Bio</td>
                  <td>Sunil is heading the master class Daaji for the last 5 years and is the zonal head representing
                    the organisation. He has organised 60 succesful workshops with Daaji.</td>
                </tr>
                <tr>
                  <td class="w-25">Designation</td>
                  <td>Project Manager</td>
                </tr>
                <tr>
                  <td class="w-25">Organisation</td>
                  <td>Heartfulness</td>
                </tr>
              </table>
              <p class="pt-3"><u>Co-odinator -</u></p>
              <table class="w-100">
                <tr>
                  <td class="w-25">Name</td>
                  <td>Sunil Rao</td>
                </tr>
                <tr>
                  <td class="w-25">Mobile Number</td>
                  <td>+91 984722983</td>
                </tr>
                <tr>
                  <td class="w-25">Email ID</td>
                  <td><a href="mailto:hemesh.j@gmail.com">hemesh.j@gmail.com</a></td>
                </tr>

              </table>
            </div>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <h4 v-b-toggle.myNotes class="d-flex">MY NOTES <img :src="expandArrow" class="ml-auto"></h4>
          <b-collapse id="myNotes" accordion="reminder-accordion" role="tabpanel" class="accordion-body">
            <div class="py-3">
              <table class="w-100">
                <tr>
                  <td class="w-25">Note</td>
                  <td>
                    <p>Sunil is heading the master class Daaji for the last 5 years and is the zonal head representing
                      the organisation. Sunil is heading the master class Daaji for the last 5 years </p>
                    <p>Sunil is heading the master class Daaji for the last 5 years and is the zonal head representing
                      the organisation. Sunil is heading the master class Daaji for the last 5 years.Sunil is heading
                      the master class Daaji for the last 5 years and is</p>
                  </td>
                </tr>
                <tr>
                  <td class="w-25">Attachment</td>
                  <td>
                    <p><a href="#">Master class list.Doc</a></p>
                    <p><a href="#">Green team list.xsl</a></p>
                  </td>
                </tr>

              </table>

            </div>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <h4 v-b-toggle.locationDetail class="d-flex">LOCATION DETAIL <img :src="expandArrow" class="ml-auto"></h4>
          <b-collapse id="locationDetail" accordion="reminder-accordion" role="tabpanel" class="accordion-body">
            <div class="py-3">
              <table class="w-100">
                <tr>
                  <td class="w-25">Meeting Location</td>
                  <td class="pl-3">
                    Taj Vivanta, Mumbai
                  </td>
                  <td class="w-10 text-right pl-3">
                    <a href="#">Copy</a>
                  </td>
                </tr>
                <tr>
                  <td class="w-25">Accomodation Location</td>
                  <td class="pl-3">
                    Chhatrapati Shivaji International Airport, T1, Off, Western Express Hwy, Navpada, Vile Parle East,
                    Santacruz East, Mumbai, Maharashtra 400099
                  </td>
                  <td class="w-10 text-right pl-3">
                    <a href="#">Copy</a>
                  </td>
                </tr>

              </table>

            </div>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <h4 v-b-toggle.addTeam class="d-flex">ADD TEAM MEMBER <img :src="expandArrow" class="ml-auto"></h4>
          <b-collapse id="addTeam" accordion="reminder-accordion" role="tabpanel" class="accordion-body">
            <div class="py-3 autoSuggest-wrap">

              <vue-autosuggest v-model="query" :suggestions="filteredOptions" @focus="focusMe" @input="onInputChange"
                @selected="onSelected" :get-suggestion-value="getSuggestionValue"
                :input-props="{id:'autosuggest__input', placeholder:'Enter Name'}" class="autoSuggest">
                <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
                  <img :src="suggestion.item.avatar" />
                  <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.name}}</div>
                </div>
              </vue-autosuggest>

            </div>
            <div class="pt-2">
              <div class="d-flex team-members-details">
                <div class="w-50">Added Team Members</div>
                <div class="w-50 text-right">99 Team Members</div>
              </div>

              <div class="team-members-list">
                <ul>
                  <li v-for="(member, id) in teamMemberList" :key="id"><img :src="member.avatar" /> {{member.name}} <a
                      href="javaScript:;" class="ml-auto"><img :src="removeIcon" width="24"></a></li>
                </ul>
              </div>
            </div>
          </b-collapse>
        </b-card>

        <b-card no-body>
          <h4 v-b-toggle.setReminder class="d-flex">SET REMINDER <img :src="expandArrow" class="ml-auto"></h4>
          <b-collapse id="setReminder" accordion="reminder-accordion" role="tabpanel">
            <h5 class="mt-2">Day Before</h5>

            <b-form-group class="custom-radio-group mt-4">
              <b-form-radio-group id="btn-radios-1" v-model="selectedDay" :options="dayOptions"
                name="radios-btn-default" buttons></b-form-radio-group>
            </b-form-group>

            <h5 class="mt-4">Time Before</h5>

            <b-form-group class="custom-radio-group mt-4">
              <b-form-radio-group id="btn-radios-1" v-model="selectedTime" :options="timeOptions"
                name="radios-btn-default" buttons></b-form-radio-group>
            </b-form-group>
            <div class="pt-3">
              <b-form-checkbox>Set reminder for all team members</b-form-checkbox>
            </div>
          </b-collapse>
        </b-card>

      </div>
 
      <div class="text-right modal-footer-button mb-2 mt-2">

        <b-button variant="outline">DECLINE</b-button>
        <b-button variant="outline">FORWARD</b-button>
        <b-button variant="outline">RESCHEDULE</b-button>

      </div>
    </div>
  </div>
</template>
<script>
  import expandArrow from '@/assets/images/expand-icon.svg'
  import removeIcon from '@/assets/images/remove-icon.svg'
  export default {
    components: {},
    data() {
      return {
        expandArrow: expandArrow,
        removeIcon: removeIcon,
        selectedDay: 'Today',
        dayOptions: [{
            text: 'Today',
            value: 'Today'
          },
          {
            text: '1 Day',
            value: '1Day'
          },
          {
            text: '2 Days',
            value: '2Days'
          },
          {
            text: '3 Days',
            value: '3Days'
          },
          {
            text: '1 week',
            value: '1week'
          },
        ],
        selectedTime: '30mins',
        timeOptions: [{
            text: '05 MINs',
            value: '05mins'
          },
          {
            text: '15 MINs',
            value: '15mins'
          },
          {
            text: '30 MINs',
            value: '30mins'
          },
          {
            text: '45 Mins',
            value: '45mins'
          },
          {
            text: '60 MINs',
            value: '60mins'
          },
        ],
        query: "",
        selected: "",
        suggestions: [{
          data: [{
              id: 1,
              name: "Frodo",
              race: "Hobbit",
              avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/4/4e/Elijah_Wood_as_Frodo_Baggins.png/220px-Elijah_Wood_as_Frodo_Baggins.png"
            },
            {
              id: 2,
              name: "Samwise",
              race: "Hobbit",
              avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/7/7b/Sean_Astin_as_Samwise_Gamgee.png/200px-Sean_Astin_as_Samwise_Gamgee.png"
            },
            {
              id: 3,
              name: "Gandalf",
              race: "Maia",
              avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Gandalf600ppx.jpg/220px-Gandalf600ppx.jpg"
            },
            {
              id: 4,
              name: "Aragorn",
              race: "Human",
              avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/3/35/Aragorn300ppx.png/150px-Aragorn300ppx.png"
            }
          ]
        }],
        teamMemberList: [{
            id: 3,
            name: "Gandalf",
            race: "Maia",
            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/e/e9/Gandalf600ppx.jpg/220px-Gandalf600ppx.jpg"
          },
          {
            id: 4,
            name: "Aragorn",
            race: "Human",
            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/3/35/Aragorn300ppx.png/150px-Aragorn300ppx.png"
          },
          {
            id: 1,
            name: "Frodo",
            race: "Hobbit",
            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/4/4e/Elijah_Wood_as_Frodo_Baggins.png/220px-Elijah_Wood_as_Frodo_Baggins.png"
          },
          {
            id: 2,
            name: "Samwise",
            race: "Hobbit",
            avatar: "https://upload.wikimedia.org/wikipedia/en/thumb/7/7b/Sean_Astin_as_Samwise_Gamgee.png/200px-Sean_Astin_as_Samwise_Gamgee.png"
          }
        ]
      }
    },

    computed: {
      filteredOptions() {
        return [{
          data: this.suggestions[0].data.filter(option => {
            return option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          })
        }];
      }
    },
    methods: {

      onSelected(item) {
        this.selected = item.item;
      },
      onInputChange(text) {
        // event fired when the input changes
        console.log(text)
      },
      /**
       * This is what the <input/> value is set to when you are selecting a suggestion.
       */
      getSuggestionValue(suggestion) {
        return suggestion.item.name;
      },
      focusMe(e) {
        console.log(e) // FocusEvent
      }
    }

  }
</script>